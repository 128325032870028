export function getUserAgent() {
    return window.navigator.userAgent.toLowerCase();
}

export function isMobile() {
    const userAgent = getUserAgent();
    const width = window.innerWidth;

    if (userAgent.includes('iphone') || userAgent.includes('android')) {
        // return 1 for phones, 2 for tablets
        return width > 677 ? 2 : 1;
    }

    return 0; // return 0 for none (desktop or unsupported devices)
}
