import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // Import useParams
import { getDetails } from './Themoviedb';
import vidAd from './image/vidAd.mp4'
import webIcon from './image/web.png'
import { getContentUrl } from './firebaseConfig';
import Subtitles from './components/Subtitles';
import { getCaptions } from './firebaseConfig';
import Shortcut from './components/shortcuts';
import loading from './image/loading.gif'



export const formatTime = (time) => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60);

    return `${hours > 0 ? `${hours}:` : ''}${hours > 0 ? (minutes < 10 ? '0' : '') : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};



export function Controls({ device = 0, onpointerdown = () => { }, containerRef, setCaptionsUrl = () => { }, videoRef, canSeek = true, skipable = 0, adLink, content, title, currentTime, duration, onSkip = (e) => { } }) {
    const [mouseX, setMouseX] = useState(0);
    const [previewTime, setPreviewTime] = useState(0);
    const [showTimer, setShowTimer] = useState(false);
    const [canSkipAd, setCanSkipAd] = useState(false);
    const [timeLeftToSkip, setTimeLeftToSkip] = useState(-skipable)
    const [showControl, setShowControl] = useState(true)
    const [isFullscreen, setIsFullscreen] = useState(false)
    const [isPlaying, setIsPlaying] = useState(!videoRef?.current?.paused)
    const [captions, setCaptions] = useState([])
    const controlsRef = useRef()

    const navigate = useNavigate()


    const [isMobile, setIsMobile] = useState(device !== 0)

    useState(() => {
        setIsMobile(device !== 0)
        console.log(device !== 0);
    }, [device])



    useEffect(() => {
        const captionsList = async () => {
            const list = await getCaptions(content?.id)

            const tr = {
                "en": "English",
                "spa": "Spanish",
                "por": "Portuguese",
                "fre": "French",
                "kor": "Korean",
                "jpn": "Japanese",
                "ht": "Haitian Creole"
            }

            const tr_list = list.items.map(i => {
                const lang = i.name.replace('.srt', '')
                return { name: tr[lang], lang: lang, url: i.fullPath }
            })

            setCaptions(tr_list);
            setCaptionsUrl(tr_list[0]?.url)
        }

        captionsList()
    }, [content, setCaptionsUrl])


    useEffect(() => {
        if (skipable > 0) {
            const time = skipable - parseInt(currentTime)
            setTimeLeftToSkip(time);

            if (time <= 0) {
                setCanSkipAd(true);
            }

        }
    }, [timeLeftToSkip, skipable, currentTime]);


    useEffect(() => {

        const container = containerRef.current
        const handleMouseMove = (e) => {
            const seekBar = document.querySelector('.seekbar');
            if (seekBar && seekBar.contains(e.target)) {
                const seekPosition = e.offsetX / seekBar.offsetWidth;
                const newTime = seekPosition * duration;
                setPreviewTime(newTime);
                setMouseX(e.pageX);
            }
        };

        container.addEventListener('mousemove', handleMouseMove);

        return () => {
            container.removeEventListener('mousemove', handleMouseMove);
        };
    }, [duration, containerRef]);




    const handleSeek = (e) => {
        e.stopPropagation()
        if (!canSeek) return
        const seekPosition = e.nativeEvent.offsetX / e.target.offsetWidth;
        const newTime = seekPosition * duration;
        videoRef.current.currentTime = newTime;
    };

    const getTime = (offset) => {
        return videoRef.current?.currentTime + offset
    }

    const togglePlay = () => {

        if (isPlaying) {
            setIsPlaying(false)
            videoRef.current?.pause()
        } else {
            setIsPlaying(true)
            videoRef.current?.play()
        }
    }

    const setSeekPosition = (time) => {
        videoRef.current.currentTime = time;
    }

    const formatLink = (adLink) => {
        const match = adLink.match(/\[(.*?)\]\((.*?)\)/)

        return {
            name: match[1],
            link: match[2]
        }
    }


    useEffect(() => {

        const container = containerRef.current
        let hideTiming;

        const handlePointerDown = (e) => {
            e.stopPropagation()
            clearTimeout(hideTiming);
            setTimeout(() => setShowControl(prev => !prev), 200)
            hideTiming = setTimeout(() => {
                setShowControl(false);
            }, 5000);
        };

        const handlePointerMove = () => {
            clearTimeout(hideTiming);
            setShowControl(true);
            hideTiming = setTimeout(() => {
                setShowControl(false);
            }, 5000);
        };

        if (isMobile) {
            container.addEventListener('pointerdown', handlePointerDown);
        } else {
            container.addEventListener('pointermove', handlePointerMove);
        }

        return () => {
            // Clean up listeners
            if (isMobile) {
                container.removeEventListener('pointerdown', handlePointerDown);
            } else {
                container.removeEventListener('pointermove', handlePointerMove);
            }
            clearTimeout(hideTiming); // Clean up timeout
        };
    }, [containerRef, isMobile, showControl]);



    const handleFullscreen = () => {
        if (document.fullscreenElement || document.webkitFullscreenElement) { // Check for fullscreen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { // Safari compatibility
                document.webkitExitFullscreen();
            }
            setIsFullscreen(false);
        } else {
            if (!containerRef || !containerRef.current) {
                throw new Error("Controls need a containerRef to be able to handle fullscreen");
            }

            const container = containerRef.current;

            if (container.requestFullscreen) {
                container.requestFullscreen();
            } else if (container.webkitRequestFullscreen) { // Safari compatibility
                container.webkitRequestFullscreen();
            } else if (videoRef.current && videoRef.current.webkitRequestFullscreen) { // Extra fallback for video element
                videoRef.current.webkitRequestFullscreen();
            }

            setIsFullscreen(true);
        }
    };



    const toggleCaption = () => {

    }


    useState(() => {
        try {
            new Shortcut({ key: "KeyF", action: () => handleFullscreen() })
            new Shortcut({ key: "Space", action: () => togglePlay() })
            new Shortcut({ key: "KeyC", action: () => toggleCaption() })
            new Shortcut({ key: "ArrowLeft", action: () => canSeek && fastSeeking(-10) })
            new Shortcut({ key: "ArrowRight", action: () => canSeek && fastSeeking(10) })
        } catch (error) {

        }

    }, [])



    const [arrowAnimState, setArrowAnimState] = useState("none")
    let x;
    const fastSeeking = (time) => {
        clearTimeout(x);
        setArrowAnimState("none"); // Reset the animation first

        setTimeout(() => {
            // Set the arrow animation based on seeking direction
            time < 0 ? setArrowAnimState("fb") : setArrowAnimState("ff");
        }, 0); // Small delay to ensure the reset happens

        // Adjust the current time for the video
        setSeekPosition(getTime(time));

        // Hide the animation after 1 second
        x = setTimeout(() => {
            setArrowAnimState("none");
        }, 1000);
    };



    return (
        <>
            <div ref={controlsRef} style={{ height: "100%", width: "100%", backgroundColor: showControl || !isPlaying ? "#00000050" : "#00000000", position: 'absolute', zIndex: 10, top: 0, display: "flex", flexDirection: "column", cursor: showControl || !isPlaying ? "default" : "none", transition: "0.2s" }}>
                <div style={{ padding: isMobile ? '30px 20px' : '50px 100px', pointerEvents: showControl ? "all" : "none", }} >
                    <ion-icon name="chevron-back-outline" style={{ color: "#fff", fontSize: "26px", cursor: 'pointer', opacity: showControl || !isPlaying ? 1 : 0, transition: "0.1s", }} onClick={() => navigate('/')}></ion-icon>
                    {/*Room for ratings logo */}
                </div>
                <div style={{ flex: 1, display: 'flex' }}
                    onpointerdown={(e) => {
                        e.stopPropagation()
                        if (isMobile) return
                        togglePlay()
                    }}>
                    <div onDoubleClick={() => canSeek && fastSeeking(-10)} style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {arrowAnimState === "fb" && (
                            <div style={{ borderRadius: '50px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "#000", padding: "20px", opacity: 0.2 }}>
                                <span className="material-icons" style={{ fontSize: '46px' }}>replay_10</span>
                            </div>
                        )}
                    </div>
                    <div style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {(!videoRef.current || videoRef.current?.buffering) && (
                            <img height={50} src={loading} alt="Loading" />
                        )}

                        {(showControl || !isPlaying) && isMobile && !videoRef.current?.buffering && (
                            <ion-icon name={!isPlaying ? "play" : "pause"} style={{ color: "#fff", fontSize: "46px" }} onClick={(e) => {
                                e.stopPropagation()
                                togglePlay()
                            }}></ion-icon>
                        )}
                    </div>

                    <div onDoubleClick={() => canSeek && fastSeeking(10)} style={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {arrowAnimState === "ff" && (
                            <div style={{ borderRadius: '50px', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "#000", padding: "20px", opacity: 0.2 }}>
                                <span className="material-icons" style={{ fontSize: '46px' }}>forward_10</span>
                            </div>
                        )}
                    </div>
                </div>
                {skipable > 0 && (
                    <div style={{ padding: '10px 100px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

                        <a href={formatLink(adLink).link} target='blank' className='button' style={{ textDecoration: 'none', fontSize: '12px', borderRadius: '20px 100px 100px 20px' }}>
                            <img
                                src={formatLink(adLink).link + "/favicon.ico"}
                                alt="Website Favicon"
                                style={{ width: "16px", height: "16px", verticalAlign: "middle" }}
                                onError={(e) => e.target.src = webIcon}
                            />
                            {formatLink(adLink).name}
                        </a>


                        <button className='button' onClick={() => canSkipAd && onSkip({ videoRef, content })}>
                            {canSkipAd ? (
                                <>Skip Ad</>
                            ) : (
                                <>Can skip ad in {timeLeftToSkip} seconds</>
                            )}
                        </button>
                    </div>

                )}
                <div style={{ padding: isMobile ? '30px 30px' : '50px 100px', paddingTop: '10px', opacity: showControl || !isPlaying ? 1 : 0, transition: "0.1s", pointerEvents: showControl ? "all" : "none", }}>
                    <div style={{ display: 'flex', fontSize: "11px", fontWeight: "bold", alignItems: 'center', gap: "20px" }}>
                        {!isMobile && showTimer && (
                            <p style={{ position: 'absolute', left: `${mouseX}px`, transform: "translateX(-50%)" }}>{formatTime(previewTime)}</p>

                        )}

                        {isMobile && (
                            <>
                                <p style={{}}>{formatTime(currentTime)}</p>
                                <p style={{ marginLeft: "auto" }}>{formatTime(duration)}</p>
                            </>
                        )}
                    </div>
                    <div style={{ display: 'flex', fontSize: "11px", fontWeight: "bold", alignItems: 'center', gap: "20px" }}>
                        {!isMobile && <p>{formatTime(currentTime)}</p>}
                        <div
                            className="seekbar"
                            style={{ flex: 1, backgroundColor: "#ffffff50", height: '5px', borderRadius: "10px", backdropFilter: "blur(5px)", position: 'relative', overflow: 'hidden' }}
                            onMouseEnter={() => setShowTimer(true)}
                            onMouseLeave={() => setShowTimer(false)}
                            onClick={handleSeek}
                        >
                            <div style={{ height: "100%", width: `${(currentTime / duration) * 100}%`, backgroundColor: canSeek ? "#fff" : "#fc0", pointerEvents: "none" }}></div>
                        </div>
                        {!isMobile && <p>{formatTime(duration)}</p>}
                    </div>


                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
                        <b style={{ flex: 1 }}>{title ? title : content?.title}</b>

                        {!isMobile && (
                            <div style={{ display: 'flex', gap: "20px", alignItems: 'center' }}>
                                {canSeek && (
                                    <span className="material-icons" style={{ cursor: canSeek ? 'pointer' : 'default' }} onClick={() => canSeek && fastSeeking(-10)}>replay_10</span>
                                )}
                                <ion-icon name={!isPlaying ? "play" : "pause"} style={{ color: "#fff", fontSize: "32px" }} onClick={() => togglePlay()}></ion-icon>
                                {canSeek && (
                                    <span className="material-icons" style={{ cursor: canSeek ? 'pointer' : 'default' }} onClick={() => canSeek && fastSeeking(10)}>forward_10</span>

                                )}
                            </div>
                        )}

                        <div style={{ display: 'flex', gap: "20px", alignItems: 'center', justifyContent: 'end', flex: 1 }}>
                            <ion-icon name="volume-medium" style={{ color: "#fff", fontSize: "26px" }}></ion-icon>
                            <span className="material-icons">closed_caption</span>
                            <span className="material-icons">settings</span>
                            {containerRef && (
                                <span className="material-icons" style={{ cursor: "pointer" }} onClick={handleFullscreen}>{isFullscreen ? "fullscreen_exit" : "fullscreen"}</span>
                            )}
                        </div>
                    </div>

                </div>
            </div>

            {captions[0] && (
                <Subtitles bottomPosition={showControl ? '120px' : '50px'} id={content?.id} videoRef={videoRef} lang={captions[0].lang} />

            )}
        </>
    )
}

function Watch() {

    const [content, setContent] = useState(null)
    const [currentTime, setCurrentTime] = useState(0); // Current time state
    const [duration, setDuration] = useState(0); // Video duration state
    const [adPlayed, setAdPlayed] = useState(0)
    const [adAmount, setAdAmount] = useState(0)
    const [url, setUrl] = useState(null)

    const videoRef = useRef(null);
    const { id } = useParams();

    const [state, setState] = useState({
        currentTime: 0,
        watched: false,
        sub: "en"
    })



    useEffect(() => {
        setAdAmount(0)
    }, [videoRef])

    useEffect(() => {
        async function fetchContent() {
            const content = await getDetails('movie', id);
            setContent(content)

            const url = await getContentUrl(content?.id)
            setUrl(url)
        }

        fetchContent()
    }, [id]);



    const handleTimeUpdate = () => {
        if (videoRef.current) {
            setCurrentTime(videoRef.current?.currentTime);
        }
    };

    const handleLoadedMetadata = () => {
        if (videoRef.current) {
            setDuration(videoRef.current?.duration);
        }
    };

    const [captionsUrl, setCaptionsUrl] = useState()



    const containerRef = useRef()

    return (
        <div ref={containerRef}>
            {url !== null && (
                <div style={{ height: "100vh", width: "100vw", backgroundColor: "black", position: 'relative', zIndex: 10 }}>

                    {adPlayed < adAmount ? (
                        <>
                            <video
                                ref={videoRef}
                                src={vidAd}
                                style={{ height: "100vh", width: "100vw" }}
                                autoPlay={true}
                                onTimeUpdate={handleTimeUpdate}
                                onLoadedMetadata={handleLoadedMetadata}
                                onEnded={() => setAdPlayed(adPlayed + 1)}
                                title={"ad"}
                            />

                            <Controls
                                containerRef={containerRef}
                                videoRef={videoRef}
                                content={content}
                                currentTime={currentTime} // Pass currentTime to Controls
                                duration={duration} // Pass duration to Controls
                                skipable={10} // 10 seconds
                                canSeek={false}
                                onSkip={() => setAdPlayed(adPlayed + 1)}
                                adLink={"[The Crow](https://thecrow.com)"}
                            />
                        </>
                    ) : (
                        <>
                            <video
                                ref={videoRef}
                                src={url}
                                style={{ height: "100vh", width: "100vw" }}
                                autoPlay={true}
                                onTimeUpdate={handleTimeUpdate}
                                onLoadedMetadata={handleLoadedMetadata}
                            >
                                <track
                                    label="English"
                                    kind="subtitles"
                                    srcLang="en"
                                    src={captionsUrl}
                                    default
                                />
                            </video>

                            <Controls
                                containerRef={containerRef}
                                videoRef={videoRef}
                                content={content}
                                currentTime={currentTime} // Pass currentTime to Controls
                                duration={duration} // Pass duration to Controls
                                setCaptionsUrl={setCaptionsUrl}
                            />
                        </>
                    )}

                </div>
            )}
        </div>

    );
}

export default Watch;
