import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { ContentMiniRow } from "./components/PreviewModal";
import { getDetails, getRecommendations } from "./Themoviedb";
import { getContentUrl, getExistingList } from "./firebaseConfig";
import { Controls } from "./WatchPage";
import ad from './image/vidAd.mp4';
import { isMobile } from "./components/mobile";

function Watch() {
    const [content, setContent] = useState(null);
    const [alikeContents, setAlikeContents] = useState([]);
    const [availableContents, setAvailableContents] = useState([]);

    const videoRef = useRef(null);
    const reflectionVideoRef = useRef(null);
    const containerRef = useRef(null);
    const [url, setUrl] = useState(null);

    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [orientation, setOrientation] = useState(window.orientation);

    const { id } = useParams();

    // Detect orientation change and set state
    useEffect(() => {
        const handleOrientationChange = () => {
            setOrientation(window.orientation);
        };

        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);

    useEffect(() => {
        const avcts = async () => {
            const data = await getExistingList();
            const list = data.prefixes.map((d) => d.name);
            setAvailableContents(list);
        };

        avcts();
    }, []);

    useEffect(() => {
        async function fetchContent() {
            const content = await getDetails("movie", id);
            setContent(content);

            const url = await getContentUrl(content?.id);
            setUrl(url);
        }

        fetchContent();
    }, [id]);

    useEffect(() => {
        async function fetchAlikeContents() {
            const alikeContents = await getRecommendations("movie", id);
            const results = await Promise.all(
                alikeContents.map((result) => {
                    return availableContents.includes(String(result.id)) ? result : null;
                })
            );
            setAlikeContents(results.filter(Boolean));
        }

        fetchAlikeContents();
    }, [id, availableContents]);

    // Sync reflection video with main video
    useEffect(() => {
        // const mainVideo = videoRef.current;
        // const reflectionVideo = reflectionVideoRef.current;

        // if (mainVideo && reflectionVideo) {
        //     const syncPlayPause = () => {
        //         reflectionVideo.currentTime = mainVideo.currentTime;
        //         mainVideo.paused ? reflectionVideo.pause() : reflectionVideo.play();
        //     };

        //     const syncTimeUpdate = () => {
        //         reflectionVideo.currentTime = mainVideo.currentTime;
        //     };

        //     mainVideo.addEventListener("play", syncPlayPause);
        //     mainVideo.addEventListener("pause", syncPlayPause);
        //     mainVideo.addEventListener("seeked", syncTimeUpdate);
        //     mainVideo.addEventListener("timeupdate", syncTimeUpdate);

        //     return () => {
        //         mainVideo.removeEventListener("play", syncPlayPause);
        //         mainVideo.removeEventListener("pause", syncPlayPause);
        //         mainVideo.removeEventListener("seeked", syncTimeUpdate);
        //         mainVideo.removeEventListener("timeupdate", syncTimeUpdate);
        //     };
        // }
    }, [url, videoRef, reflectionVideoRef]);

    const handleTimeUpdate = () => {
        if (videoRef.current) {
            setCurrentTime(videoRef.current.currentTime);
        }
    };

    const handleLoadedMetadata = () => {
        if (videoRef.current) {
            setDuration(videoRef.current.duration);
        }
    };

    const [y, setY] = useState(0);
    const handleSwiping = () => setY(-100);

    // Show landscape mode warning if device is in portrait
    const isLandscape = orientation === 90 || orientation === -90;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', transform: `translateY(${y}px)`, height: '100vh' }}>
            {(isLandscape || isMobile() === 0) ? (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    width: "100vw",
                    height: "100vh"
                }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div style={{ width: '100%', height: "100%", backgroundColor: '#5550', position: 'relative' }}>
                            {/* Background reflection video */}
                            {/* <video
                                ref={reflectionVideoRef}
                                src={url}
                                style={{
                                    width: "100vw",
                                    height: "100vh",
                                    objectFit: 'cover',
                                    filter: 'blur(100px)',
                                    position: 'absolute',
                                    zIndex: -1
                                }}
                                muted={true}
                                autoPlay={true}
                                playsInline
                                webkit-playsinline
                            /> */}
                            <div ref={containerRef} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}>
                                <video
                                    ref={videoRef}
                                    src={url}
                                    style={{
                                        width: "100vw",
                                        height: "100vh",
                                    }}
                                    autoPlay={true}
                                    playsInline
                                    webkit-playsinline
                                    onTimeUpdate={handleTimeUpdate}
                                    onLoadedMetadata={handleLoadedMetadata}
                                />
                                <Controls
                                    containerRef={containerRef}
                                    videoRef={videoRef}
                                    content={content}
                                    currentTime={currentTime}
                                    duration={duration}
                                    onpointerdown={handleSwiping}
                                    device={isMobile()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                    textAlign: 'center',
                    flexDirection: 'column',
                    padding: '30px'
                }}>
                    <ion-icon name="phone-landscape-outline" style={{ color: 'white', fontSize: '64px' }}></ion-icon>
                    <p>Please rotate your device to landscape mode to watch the video.</p>
                </div>
            )}
        </div>
    );
}

export default Watch;
