import './style.css';
import { useAuth } from '../AuthContext'; // Import the AuthContext
import { useNavigate, useLocation, Link, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// import { getContent } from './Contents';
import { search as srch } from '../Themoviedb';
import { getExistingList } from '../firebaseConfig';
import logo from '../image/logo.png'


function Header() {
    const { isLoggedin, currentUser, logout, account, setCurrentUser } = useAuth(); // Consume auth context

    const [search, setSearch] = useState("")
    const [contents, setContents] = useState([])

    const [showResult, SetShowResult] = useState(false)
    const [availableContents, setAvailableContents] = useState([])

    useEffect(() => {
        const avcts = async () => {
            const data = await getExistingList()
            const list = data.prefixes.map(d => d.name)
            setAvailableContents(list)
        }

        avcts()
    }, [])

    useEffect(() => {
        function getContents() {
            srch('multi', search).then(async data => {
                // setContents(data.results || [])
                const results = await Promise.all(
                    data.results.map(async (result) => {
                        return availableContents.includes(String(result.id)) ? result : null
                    })
                );

                // Filter out the null values (movies that don't exist in storage)
                const filteredResults = results.filter(result => result !== null);
                setContents(filteredResults);  // Set the filtered movies
            })
        }

        getContents();
    }, [search, availableContents]);


    const currentPath = encodeURIComponent(window.location.pathname);

    const users = account?.profiles;


    const navigate = useNavigate();
    const location = useLocation();

    // useEffect(() => {
    //     setSearch("")
    // }, [location])

    const [width, setWidth] = useState(window.innerWidth)

    window.onresize = () => {
        setWidth(window.innerWidth)
    }



    const switchProfile = (token, redirectPath) => {
        try {
            const user = account?.profiles?.find(profile => profile.token === token);
            if (!user) return;

            setCurrentUser(user);
            localStorage.setItem('currentUser', user.token);
            navigate(redirectPath.replace(/(%2F)/g, '/'));
        } catch (error) {
            console.error('Failed to switch profile:', error);
        }
    };




    return (
        <header className="Header" style={{ backgroundColor: '#0005', backdropFilter: 'blur(20px)', WebkitBackdropFilter: "blur(10px)" }}>
            {account && !currentUser && <Navigate to={"/profiles"} />}
            <div className={`searchContainer ${search.trim().length > 0 && "searching"}`} onFocus={() => SetShowResult(true)} onBlurCapture={() => SetShowResult(false)} >
                <div className={`search`}>
                    <ion-icon name="search"></ion-icon>
                    <input value={search} onChange={(e) => setSearch(e.target.value)} className="searchInput" placeholder="Search Plinx" />
                    {search.trim().length > 0 && (
                        <ion-icon name="close-circle" style={{ cursor: 'pointer' }} onClick={() => setSearch("")}></ion-icon>
                    )}
                </div>

                {search.trim().length > 0 && showResult && (
                    <div className='result' style={{ WebkitBackdropFilter: "blur(10px)" }}>
                        {contents.map(content => (
                            <Link className='result-item' key={content.id} to={`/${content.media_type}/${content.id}`} state={{ backgroundLocation: location }} onMouseDown={(e) => e.preventDefault()}>
                                <div style={{ height: '75px', aspectRatio: content.content_type === "Song" ? 1 : 2 / 3, backgroundColor: '#fff1', borderRadius: '10px', backgroundImage: `url(https://image.tmdb.org/t/p/original/${content.poster_path || content.profile_path})`, backgroundSize: 'cover' }}></div>
                                <div style={{ flex: 1, padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <b>{content.title || content.name}</b>

                                    <p>{content.known_for_department || new Date(content.release_date || content.first_air_date).getFullYear()} ‧ {(() => {
                                        switch (content.media_type) {
                                            case "movie":
                                                return "Movie"
                                            case "tv":
                                                return "TV Show"
                                            case "person":
                                                return "Person"
                                            default:
                                                return ""
                                        }
                                    })()}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                )}
            </div>

            {width < 767 && (
                <div style={{ backgroundColor: "#fff0", height: "30px", pointerEvents: "none", margin: 'auto 0px' }}>
                    <img src={logo} alt='logo' height={30} />
                </div>
            )}

            {width > 767 && (

                <div className='menu' style={{ transition: '1s' }}>
                    <div href="/" className="pageLink active menuToggle">Menu <ion-icon name="chevron-down"></ion-icon></div>
                    <div className="pageLinks">
                        <div onClick={() => navigate("/")} className={`pageLink ${location.pathname === "/" && 'active'}`}>Browse</div>
                        <div onClick={() => navigate("movies")} className={`pageLink ${location.pathname === "/movies" && 'active'}`}>Movies</div>
                        <div onClick={() => navigate("tvs")} className={`pageLink ${location.pathname === "/tvs" && 'active'}`}>TV Series</div>
                        <div onClick={() => navigate("#categories")} className={`pageLink ${location.hash === "#categories" && 'active'}`}>Genres</div>
                    </div>
                </div>
            )}

            {!account ? (
                <div className="userArea">
                    <a href={`/login`} className="user">
                        <span className="username" style={{ padding: "20px 10px 20px 20px" }}>Login</span>
                    </a>
                </div>
            ) : (
                <div className="userArea">
                    <div className="user expandUser" onClick={() => navigate("/profiles")}>
                        <div className="pfp" style={{ backgroundImage: `url(${currentUser?.profileUrl})` }}></div>
                        <span className="username">{currentUser?.username}</span>
                        <ion-icon name="chevron-down"></ion-icon>
                    </div>

                    <div className="usersList">
                        {/* Iterate through users except the current user */}
                        {users
                            ?.filter(user => user.username !== currentUser?.username)
                            .map(user => (
                                <div
                                    key={user?.uid || Math.random()}
                                    onClick={() => switchProfile(user.token, currentPath)}
                                    className="user"
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="pfp" style={{ backgroundImage: `url(${user.profileUrl})` }}></div>
                                    <span className="username">{user?.username}</span>
                                </div>
                            ))
                        }

                        <div onClick={() => navigate(`/new-user`)} className="user">
                            <div className="pfp">
                                <ion-icon name="person-add" style={{ color: "#ffffff" }}></ion-icon>
                            </div>
                            <span className="username">Create New</span>
                        </div>

                        <Link to={`/account`} className="user">
                            <div className="pfp">
                                <ion-icon name="person" style={{ color: "#ffffff" }}></ion-icon>
                            </div>
                            <span className="username">Account</span>
                        </Link>

                        <Link to={`/login`} onClick={logout} className="user">
                            <div className="pfp">
                                <ion-icon name="power" style={{ color: "#ffffff" }}></ion-icon>
                            </div>
                            <span className="username">Logout</span>
                        </Link>
                    </div>
                </div>
            )}

        </header>
    );
}

export default Header;
