// Shortcut.js
// Created by Sterly on 09/29/2024 at 12:13 PM


export default class Shortcut {
    constructor({ key = "", mouse = "", altKey = false, shiftKey = false, action = () => { } }, document = window.document) {
        this.key = key; // (e.g: "q")
        this.mouse = mouse; // (e.g: "wheel", "left click", "right click", "double click")
        this.altKey = altKey;
        this.shiftKey = shiftKey;
        this.action = action;
        this.document = document;

        this.bind(); // Automatically bind the shortcut to the event
    }

    // Method to check if the shortcut matches a given key event
    matchesKeyEvent(event) {
        return event.keyCode === this.key &&
            event.altKey === this.altKey &&
            event.shiftKey === this.shiftKey;
    }

    // Method to check if the shortcut matches a given mouse event
    matchesMouseEvent(event) {
        if (this.mouse === "wheel" && event.type === "wheel") {
            return true;
        }
        if (this.mouse === "left click" && event.type === "click" && event.button === 0) {
            return true; // Left click is usually button 0
        }
        if (this.mouse === "right click" && event.type === "contextmenu") {
            return true; // Right click triggers the contextmenu event
        }
        if (this.mouse === "double click" && event.type === "dblclick") {
            return true; // Double click event
        }
        return false;
    }

    // Method to execute the action with extended event metadata
    execute(metadata) {
        this.action(metadata); // Pass the metadata to the action
    }

    // Bind the shortcut to the appropriate events (keyboard and mouse)
    bind() {
        if (this.key) {
            this.document.addEventListener("keydown", (event) => {
                if (this.matchesKeyEvent(event)) {
                    const metadata = this.buildEventMetadata(event, "keydown");
                    this.execute(metadata);
                }
            });
        }

        if (this.mouse) {
            // Listen for the appropriate mouse events
            const mouseEvent = this.mouse === "double click" ? "dblclick" :
                this.mouse === "right click" ? "contextmenu" : "click";

            this.document.addEventListener(mouseEvent, (event) => {
                if (this.matchesMouseEvent(event)) {
                    const metadata = this.buildEventMetadata(event, mouseEvent);
                    this.execute(metadata);
                }
            });

            // Add an extra listener for "wheel" if applicable
            if (this.mouse === "wheel") {
                this.document.addEventListener("wheel", (event) => {
                    if (this.matchesMouseEvent(event)) {
                        const metadata = this.buildEventMetadata(event, "wheel");
                        this.execute(metadata);
                    }
                });
            }
        }
    }

    // Method to build metadata from the event
    buildEventMetadata(event, eventType) {
        return {
            type: eventType, // Type of event (e.g., "keydown", "click", "wheel")
            key: event.key || null, // Key pressed (if applicable)
            altKey: event.altKey, // Alt key pressed
            shiftKey: event.shiftKey, // Shift key pressed
            ctrlKey: event.ctrlKey || false, // Ctrl key pressed (if applicable)
            metaKey: event.metaKey || false, // Meta key pressed (if applicable)
            mouseX: event.clientX || null, // Mouse X position (if applicable)
            mouseY: event.clientY || null, // Mouse Y position (if applicable)
            wheelDelta: event.deltaY || null, // Wheel delta (for scroll events)
            button: event.button || null // Mouse button (if applicable)
        };
    }
}
