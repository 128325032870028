// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";

import { callSetCustomClaimsFunction } from './customClaims'; // Assuming you have a function to call Firebase Function

import { getDatabase, ref, get, child, set } from "firebase/database";
import { getStorage, ref as storef, getDownloadURL, listAll, getMetadata } from "firebase/storage";



// Your Firebase config from the Firebase Console
const firebaseConfig = {
    apiKey: "AIzaSyB239Mz0umjbOdzFSrA5sbPSAw-2hOr0Kc",
    authDomain: "plinx-stream.firebaseapp.com",
    databaseURL: "https://plinx-stream-default-rtdb.firebaseio.com",
    projectId: "plinx-stream",
    storageBucket: "plinx-stream.appspot.com",
    messagingSenderId: "95680791020",
    appId: "1:95680791020:web:fe644199cb15c15a3b80c9",
    measurementId: "G-06F2FLX739"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export async function fetchSharedToken(token) {
    const auth = getAuth();
    const database = getDatabase();

    try {
        // Sign in the user anonymously
        const userCredential = await signInAnonymously(auth);
        const user = userCredential.user;

        // Check if the token exists in the Realtime Database
        const dbRef = ref(database);
        const snapshot = await get(child(dbRef, `shares/${token}`));

        if (snapshot.exists()) {
            const sharedData = snapshot.val();

            // If the token is valid, call a Firebase Function to set custom claims
            if (sharedData.isActive) {
                // Call Firebase Function to set custom claims based on token data
                const response = await callSetCustomClaimsFunction(user.uid, token);
                console.log('Custom claims set:', response);
                return { valid: true, data: sharedData };
            } else {
                console.error("Token is not active or has expired.");
                return { valid: false, error: "Token is not active or has expired." };
            }
        } else {
            console.error("Token does not exist.");
            return { valid: false, error: "Token does not exist." };
        }
    } catch (error) {
        console.error("Error fetching token from the database:", error);
        return { valid: false, error: error.message };
    }
}

export async function getTrailerUrl(id, episode = "trailer") {
    const storage = getStorage();

    try {
        // Check if the file exists by trying to get its metadata
        const fileRef = storef(storage, `/${id}/${episode}.mp4`);
        await getMetadata(fileRef);

        // If metadata is fetched, get the download URL
        const result = await getDownloadURL(fileRef);
        return result;
    } catch (error) {
        // Return null if the file doesn't exist or an error occurs
        return null;
    }
}



export async function getContentUrl(id, episode = "film") {
    const storage = getStorage();

    try {
        const result = await getDownloadURL(storef(storage, `/${id}/${episode}.mp4`))
        return result
    } catch (error) {
        return null
    }

}

export async function getCaptionUrl(id, lang = "en") {
    const storage = getStorage();

    const result = await getDownloadURL(storef(storage, `/${id}/subs/${lang}.srt`))

    return result
}


export async function getCaptions(id) {
    const storage = getStorage();
    const ref = storef(storage, `/${id}/subs/`)

    const list = await listAll(ref)


    return list
}




export const getExistingList = async () => {
    const storage = getStorage();
    const fileRef = storef(storage, ``);
    const list = await listAll(fileRef)
    return list;  // File exists if no error is thrown
};



export const addToMylist = async (userId, profileToken, id) => {
    const db = getDatabase();

    // Get a reference to the user's profiles
    const profilesRef = ref(db, `users/${userId}/profiles`);

    // Fetch the profiles data
    const snapshot = await get(profilesRef);
    const profiles = snapshot.exists() ? snapshot.val() : [];

    // Find the index of the profile with the matching token
    const profileIndex = Object.keys(profiles).find(index => profiles[index].token === profileToken);

    if (profileIndex === undefined) {
        throw new Error('Profile with the specified token not found.');
    }

    // Get a reference to the "My List" of the specific profile
    const myListRef = ref(db, `users/${userId}/profiles/${profileIndex}/library/myList`);

    // Fetch the current "My List"
    const myListSnapshot = await get(myListRef);
    const myList = myListSnapshot.exists() ? myListSnapshot.val() : [];

    // Add the new item at the next available index (based on the length)
    set(ref(db, `users/${userId}/profiles/${profileIndex}/library/myList/${myList.length}`), id);
};


export const setMylist = async (userId, profileToken, array) => {
    const db = getDatabase();

    // Get a reference to the user's profiles
    const profilesRef = ref(db, `users/${userId}/profiles`);

    // Fetch the profiles data
    const snapshot = await get(profilesRef);
    const profiles = snapshot.exists() ? snapshot.val() : [];

    // Find the index of the profile with the matching token
    const profileIndex = Object.keys(profiles).find(index => profiles[index].token === profileToken);

    if (profileIndex === undefined) {
        throw new Error('Profile with the specified token not found.');
    }



    // Add the new item at the next available index (based on the length)
    set(ref(db, `users/${userId}/profiles/${profileIndex}/library/myList/`), array);
};




// Export Firebase auth
export const auth = getAuth(app);
